<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      vertical-scroll
      :filter-names="filterNames"
      @filterOption="onFiltersChange"
    >
      <template v-slot:terminal_name="{ item }">
        <div class="text-capitalize">
          {{ item.terminal_name }}
        </div>
      </template>

      <template v-slot:fuel_tank_name="{ item }">
        <div class="text-capitalize">
          {{ item.fuel_tank_name }}
        </div>
      </template>
      <template v-slot:make="{ item }">
        <div class="text-capitalize">
          {{ item.make }}
        </div>
      </template>
      <template v-slot:model="{ item }">
        <div class="text-capitalize">
          {{ item.model }}
        </div>
      </template>
      <template v-slot:operational_status="{ item }">
        <div> {{ (item.operational_status) ? 'Active' : 'Inactive' }}</div>
      </template>
      <template v-slot:actions="{ item }">
        <v-icon
          v-if="isEdit"
          class="mr-2"
          small
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="isDelete"
          class="mr-2"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template #addFilters>
        <v-card>
          <v-select
            v-model="customFilter.terminal_id"
            filled
            dense
            clearable
            :items="terminalList"
            item-value="id"
            item-text="name"
            :label="$t('terminal')"
            @change="onTerminalChange"
          />
        </v-card>
        <v-card>
          <v-select
            v-model="customFilter.fuel_id"
            filled
            dense
            clearable
            :items="fuelList"
            item-value="id"
            item-text="name"
            :label="$t('fuel_tank')"
            @change="fetchData"
          />
        </v-card>
        <v-card>
          <v-select
            v-model="customFilter.operational_status"
            filled
            dense
            clearable
            :items="statusOptions"
            item-value="id"
            item-text="name"
            :label="$t('status')"
            @change="fetchData"
          />
        </v-card>
      </template>
      <!-- add Filters -->
      <!-- <template #addFilters>
          <v-card v-if="authUser.isAdmin">

          </v-card>
        </template> -->
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <div>
          <create-dispenser
            v-if="isCreate"
            :filter-options="options"
          />
          <edit-dispenser
            v-if="(isEdit) && editModal"
            :is-visible-dialog="editModal"
            :toggle-edit="toggleEditModal"
            :item="selectedRow"
          />
          <confirmation-modal
            v-if="isDelete"
            :is-visible="deleteModal"
            :toggle="toggleDeleteModal"
            :row="selectedRow"
          />
        </div>
      </template>
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    CreateDispenser: () => import("./dialogs/CreateDispenser.vue"),
    EditDispenser: () => import("./dialogs/EditDispenser.vue"),
    ConfirmationModal: () =>
      import("@/components/Modals/ConfirmationModal.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      statusOptions: [
        {
          id: true,
          name: "active",
        },
        {
          id: false,
          name: "inactive",
        },
      ],
      permissions: {
        view: "dispensers.view",
        create: "dispensers.create",
        edit: "dispensers.edit",
        delete: "dispensers.delete",
      },
      isMounted: false,
      customFilter: {},
      filters: {},
      subFilters: {},
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "fuelDispenser/getLoading",
      meta: "fuelDispenser/getMeta",
      list: "fuelDispenser/getList",
      terminalList: "terminal/getTerminalDropDown",
      fuelList: "fuelTanks/getDropDown",
    }),
    _headers() {
      const head = [
        {
          text: this.$t("id"),
          align: "left",
          sortable: true,
          value: "id",
        },
        {
          text: this.$t("tenant_name"),
          align: "left",
          sortable: false,
          value: "tenant_name",
          showAdmin: true,
        },
        {
          text: this.$t("terminal"),
          align: "left",
          sortable: false,
          value: "terminal_name",
        },
        {
          text: this.$t("fuel_tank"),
          align: "left",
          sortable: false,
          value: "fuel_tank_name",
        },

        {
          text: this.$t("md_makes"),
          align: "left",
          sortable: false,
          value: "make",
        },
        {
          text: this.$t("model"),
          align: "left",
          sortable: false,
          value: "model",
        },
        {
          text: this.$t("serial_number"),
          align: "left",
          sortable: false,
          value: "serial_number",
        },
        {
          text: this.$t("year"),
          align: "left",
          sortable: false,
          value: "model_year",
        },
        {
          text: this.$t("imei"),
          align: "left",
          sortable: false,
          value: "iot_device_imei",
        },
        {
          text: this.$t("status"),
          align: "left",
          sortable: false,
          value: "operational_status",
        },
        ...(this.isEdit || this.isDelete
          ? [
            {
              text: this.$t("actions"),
              align: "left",
              sortable: false,
              value: "actions",
            },
          ]
          : []),
      ];

      // computed headers for showing columns to role based
      return head.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },

    filterNames() {
      if (this.authUser.isAdmin) {
        return [];
      } else {
        return [];
      }
    },
  },
  watch: {
    // "options.terminal_id": {
    //   handler: function (val) {
    //     if (this.isMounted) {
    //       this.terminal_id = "";
    //     }
    //     this.isMounted = true;
    //     if (val) {
    //       const params = { terminal_id: val };
    //       this.$store.dispatch("fuelTanks/dropDownList", params);
    //     } else {
    //       this.$store.commit("fuelTanks/SET_LIST_DROPDOWN", []);
    //     }
    //   },
    // },
    options(val) {
      if (this.authUser.isAdmin) {
        if (!val.tenant_id) {
          this.options.tenant_id = val.fleetId
        }
        if (this.isMounted) {
          this.terminal_id = "";
        }
        this.isMounted = true;
        if (this.options.tenant_id) {
          const params = { tenant_id: this.options.tenant_id };
          this.$store.dispatch("terminal/dropDownList", params);

        } else {
          this.$store.commit("terminal/SET_LIST_DROPDOWN", []);
        }
      }
      this.fetchData();
    },
  },


  async mounted() {
    this.$store.dispatch("fuelDispenser/setLoadingValue", true);
    if (!this.authUser.isAdmin) {
      const params = { tenant_id: this.authUser.tenant_id };
      await this.$store.dispatch("terminal/dropDownList", params);
    }
    if (this.$route?.query?.terminal_id) {
      this.customFilter.terminal_id = this.$route.query.terminal_id;
      if(this.customFilter.terminal_id){
            this.$store.dispatch("fuelTanks/dropDownList",{ terminal_id:this.customFilter.terminal_id});
          }else{
          this.$store.commit("fuelTanks/SET_LIST_DROPDOWN", []);
          }
    }
    if (this.$route?.query?.fuel_id) {
      this.customFilter.fuel_id = (this.$route.query.fuel_id);
    }
    if (this.$route?.query?.operational_status) {
      this.customFilter.operational_status = (this.$route.query.operational_status);
    }
  },
  beforeDestroy() {
    let par = { ...this.customFilter, ...this.options }
    const params = {
      name: "dispensers",
      filters: par,
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    onTerminalChange(v) {
      delete this.customFilter.fuel_id
      const params = { terminal_id: v };
      this.$store.dispatch("fuelTanks/dropDownList", params);
      this.fetchData()
    },
    async fetchData() {
      this.options.page = 1;
      await this.$store
        .dispatch("fuelDispenser/list", { ...this.options, ...this.customFilter })
        .then(() => {
          if (this.options.page > (this.meta?.lastPage || 1)) {
            this.options.page = 1;
          }
        });
    },

    // Confirmation of Delete Row
    async deleteSelectedRow(params) {
      try {
        await this.$store.dispatch("fuelDispenser/destroy", params);
        this.$store.dispatch("alerts/success", this.$t("deleted_successfully"));
        if (this.list.length === 0 && this.meta.lastPage == this.options.page) {
          this.options.page -= 1; // Move to the previous page
        }
        this.toggleDeleteModal("close");
        this.fetchData();
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },
};
</script>